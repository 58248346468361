import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-checkmark.js';
/** @polymer */
class JhaAuthConfirmationElement extends PolymerElement {
  static get is() {
    return 'jha-auth-confirmation';
  }
  connectedCallback() {
    super.connectedCallback();

    this.$['submit-button'].tabIndex = '-1';
    this.$['submit-button'].focus();
  }
  _done() {
    this.$['submit-button'].isLoading = false;
    this.dispatchEvent(new CustomEvent('confirm'));
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      a {
        color: var(--jha-color-primary);
        text-decoration: none;
      }
      header,
      article,
      footer {
        text-align: center;
        padding-bottom: 24px;
      }
      footer {
        border-top: 1px solid var(--jha-border-color);
        padding: 32px;
      }
      header {
        padding: 16px 16px 0;
        border-bottom: 1px solid var(--jha-border-color);
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 18px;
        font-weight: 400;
        display: inline-block;
      }
      article {
        padding: 32px;
      }
      p {
        margin: 20px 20px 20px 20px;
        text-align: center;
      }
      jha-icon-checkmark {
        width: 36px;
        height: 36px;
        padding: 12px;
        border-radius: 50%;
        fill: white;
        background: var(--jha-color-success);
      }
    </style>
    <header>
      <h2>You're all set!</h2>
    </header>
    <article>
      <jha-icon-checkmark></jha-icon-checkmark>
      <p>
        Thanks for helping us make your account secure.
      </p>
    </article>
    <footer>
      <jha-button type="button" id="submit-button" on-keypress="_done" on-click="_done" wide="">
        Done
      </jha-button>
    </footer>
  `;
  }
}
customElements.define(JhaAuthConfirmationElement.is, JhaAuthConfirmationElement);
export default JhaAuthConfirmationElement;

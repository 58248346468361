import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import './jha-auth-enroll.js';
import './jha-auth-verify.js';
import './jha-auth-confirmation.js';
import '@banno/platform-ux-shared/components/polymer3/jha/toasts/jha-toast.js';
import TwoFactorController from '@banno/platform-ux-shared/controllers/two-factor-controller.js';
/** @polymer */
class JhaAuthElement extends PolymerElement {
  static get is() {
    return 'jha-auth';
  }
  static get properties() {
    return {
      email: String,
      isEnrolled: Boolean,
      isEnrolling: Boolean,
      isOpen: {
        type: Boolean,
        value: false,
        observer: JhaAuthElement.prototype._isOpenToggled
      },
      isVerifying: Boolean,
      isValidCode: Boolean,
      openDialog: String,
      phone: String,
      authRequired: Boolean,
      userId: String,
    };
  }

  _close() {
    this.dispatchEvent(new CustomEvent('close', {bubbles: true}));
  }

  _redirect() {
    this.isOpen = false;
    this.dispatchEvent(new CustomEvent('auth-verified'));
  }

  _changeDialog(view) {
    if (view === 'verify') {
      this._sendCode();
    }
    this.openDialog = view;
  }

  _enrollEvent(e) {
    this._enroll(e.detail.email, e.detail.phone, e.detail.countryCode);
  }

  _enroll(email, phone, countryCode) {
    this.phone = phone;
    this.isEnrolling = true;
    TwoFactorController.enroll({
      email,
      phone,
      countryCode,
    }).then(() => {
      this._changeDialog('verify');
      this.isEnrolling = false;
    }).catch((e) => {
      this.showToast('Enrollment error');
      this.phone = '';
      this.isEnrolling = false;
    });
  }

  _isOpenToggled(isOpen) {
    if (isOpen) this._open2FADialog();
  }

  _open2FADialog() {
    if (!this.isEnrolled) {
      this.phone = '';
      this._changeDialog('enroll');
    } else {
      this._changeDialog('verify');
    }
  }

  _sendCodeAlt(e) {
    const args = {
      via: e.detail || 'sms',
      force: true,
      userId: this.userId
    };
    TwoFactorController.sendCode(args);
  }

  _resendCode() {
    this.showToast('Verification code sent');
    this._sendCode();
  }

  _sendCode() {
    TwoFactorController.sendCode({userId: this.userId});
  }

  showToast(text) {
    this.$.toast.textContent = text;
    this.$.toast.setAttribute('is-visible', true);
    setTimeout(() => {
      this.$.toast.removeAttribute('is-visible');
    }, 5000);
  }

  _showView(view) {
    return this.openDialog === view;
  }

  _verifyEvent(e) {
    this._verify(e.detail.code, e.detail.rememberDevice);
  }

  _verify(code, rememberDevice) {
    this.isVerifying = true;
    TwoFactorController.verifyCode(this.userId, code, rememberDevice).then(() => {
      this.isVerifying = false;
      if (!this.isEnrolled) {
        this._changeDialog('confirm');
      } else {
        this._redirect();
      }
    }).catch((e) => {
      this.isVerifying = false;
      this.shadowRoot.querySelector('jha-auth-verify').codeIsInvalid();
    });
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      .toast-container {
        pointer-events: none;
        position: fixed;
        z-index: 1060;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      @media (min-width: 415px) {
        .toast-item{
          max-width: 288px;
          border-radius: 2px;
          bottom: 16px;
          left: 16px;
          width: auto;
        }
      }
      @media (min-width: 740px) {
        .toast-item{
            bottom: 32px;
            left: 32px;
            width: auto;
        }
      }
      @media (max-width: 739px) {
        .toast-container {
            bottom: 40px;
        }
      }
    </style>
    <template is="dom-if" if="[[_showView('enroll', openDialog)]]">
      <jha-auth-enroll email="[[email]]" on-enroll="_enrollEvent" auth-required="[[authRequired]]" is-loading="[[isEnrolling]]" phone="[[phone]]" on-close="_close"></jha-auth-enroll>
    </template>
    <template is="dom-if" if="[[_showView('verify', openDialog)]]">
      <jha-auth-verify on-resend-code="_resendCode" on-send-alt-code="_sendCodeAlt" on-verify="_verifyEvent" enrolled="[[isEnrolled]]" is-loading="[[isVerifying]]" phone="[[phone]]"></jha-auth-verify>
    </template>
    <template is="dom-if" if="[[_showView('confirm', openDialog)]]">
      <jha-auth-confirmation on-confirm="_redirect"></jha-auth-confirmation>
    </template>
    <div class="toast-container">
      <jha-toast class="toast-item" id="toast"></jha-toast>
    </div>
  `;
  }
}
customElements.define(JhaAuthElement.is, JhaAuthElement);
export default JhaAuthElement;

import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-checkbox.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-arrow-back.js';
import '@banno/platform-ux-shared/components/polymer3/forms/jha-dashboard-radio-button.js';
/** @polymer */
class JhaAuthVerifyElement extends PolymerElement {
  static get is() {
    return 'jha-auth-verify';
  }
  static get properties() {
    return {
      code: {
        type: String,
        observer: JhaAuthVerifyElement.prototype._codeUpdated
      },
      enrolled: Boolean,
      isLoading: {
        type: Boolean,
        value: false
      },
      method: String,
      showAnotherWay: {
        type: Boolean,
        value: false
      },
      phone: String,
      _errorMessage: String
    };
  }
  constructor() {
    super();
    this.boundKeyHandler_ = this._keyHandler.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.boundKeyHandler_);
  }

  _keyHandler(e) {
    const ENTER = 13;
    const pressedKey = e.keyCode;
    if (pressedKey === ENTER) {
      this._next(e);
    }
  }

  _anotherWay() {
    this.showAnotherWay = true;
  }

  _isMethodSelected(method) {
    return method;
  }

  _getMaskedPhone(phone) {
    const length = phone.length;
    if (phone) return '(\u2022\u2022\u2022) \u2022\u2022\u2022-\u2022\u2022' + phone.slice(length - 2, length);
  }

  _goBack() {
    this.showAnotherWay = false;
  }

  _codeUpdated(newCode) {
    if (newCode ===  '') {
      this.shadowRoot.querySelector('jha-form-floating-group').removeAttribute('has-value');
    }
    this._resetValidity();
  }

  _continueWithNewMethod() {
    const method = this.method === 'call' ? 'call' : 'sms';
    this.dispatchEvent(new CustomEvent('send-alt-code', {
      composed: true,
      detail: method
    }));
    this.method = '';
    this.newMethodLoading = false;
    this._goBack();
  }

  _methodChanged(event) {
    this.method = event.detail;
    event.preventDefault();
  }

  _isValid(code) {
    return code && code.length > 4 && code.length < 9;
  }

  _next(e) {
    e.preventDefault();
    const checkboxElement = this.root.querySelector('jha-form-checkbox[name=rememberDevice]');
    const rememberDevice = checkboxElement ? checkboxElement.checked : false;
    if (this._isValid(this.code)) {
      this.dispatchEvent(new CustomEvent('verify', {
        bubbles: true,
        composed: true,
        detail: {
          code: this.code,
          rememberDevice
        }
      }));
    }
  }

  _resend() {
    this.dispatchEvent(new CustomEvent('resend-code'));
  }

  _resetValidity() {
    if (this.code) {
      this._errorMessage = null;
    } else {
      this.shadowRoot.querySelector('input').focus();
    }
  }

  codeIsInvalid() {
    this._errorMessage = 'Oops. Double-check your code';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      a {
        color: var(--jha-color-primary);
        text-decoration: none;
        cursor: pointer;
      }
      header,
      footer {
        text-align: center;
      }
      header {
        position: relative;
        padding: 16px 16px 0;
        border-bottom: 1px solid var(--jha-border-color);
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 18px;
        font-weight: 400;
        display: inline-block;
      }
      form {
        text-align: left;
        color: var(--jha-text-light);
      }
      label {
        padding-top: 0;
      }
      .verification-code-input {
        font-size: 18px;
        max-width: 150px;
      }
      article {
        padding: 32px;
      }
      p {
        margin: 0 0 20px;
        text-align: left;
      }
      .try-again {
        margin-top: 24px;
      }
      jha-dashboard-radio-button {
        margin-bottom: 24px;
      }
      .option-title {
        font-size: 15px;
        color: var(--jha-text-dark);
        margin-bottom: 4px;
      }
      jha-form-floating-group {
        margin: 0 0 20px;
      }
      jha-icon-arrow-back {
        fill: var(--jha-text-dark);
        position: absolute;
        left: 32px;
        top: 32px;
        cursor: pointer;
      }
      footer {
        border-top: 1px solid var(--jha-border-color);
        padding: 32px 44px;
      }
      .helper-text {
        padding-top: 24px;
        line-height: 1.5;
        font-size: 13px;
        color: var(--jha-text-light);
      }
      .authy-icon {
        width: 32px;
        height: 32px;
        float: right;
        margin-left: 16px;
      }
    </style>
    <template is="dom-if" if="[[showAnotherWay]]">
      <header>
        <jha-icon-arrow-back on-click="_goBack"></jha-icon-arrow-back>
        <h2>Get your code another way</h2>
      </header>
      <article>
        <form>
          <jha-dashboard-radio-button on-radio-button-selected="_methodChanged" identifier="call" selectedidentifer="[[method]]">
            <div class="option-title">Phone call</div>
            <div>[[_getMaskedPhone(phone)]]</div>
          </jha-dashboard-radio-button>
          <jha-dashboard-radio-button on-radio-button-selected="_methodChanged" identifier="sms" selectedidentifer="[[method]]">
            <div class="option-title">Text message</div>
            <div>[[_getMaskedPhone(phone)]]</div>
          </jha-dashboard-radio-button>
          <jha-dashboard-radio-button on-radio-button-selected="_methodChanged" identifier="authy" selectedidentifer="[[method]]">
            <div class="option-title">Authy app</div>
            <div>
              <a href="https://authy.com/download/" target="_blank" rel="noopener" class="authy-icon">
                <svg width="32px" height="32px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
                  <g>
                    <path d="M256,127.997518 C256,198.696135 198.694024,256 127.996691,256 C57.3043214,256 2.84217094e-14,198.696135 2.84217094e-14,127.997518 C2.84217094e-14,57.3046917 57.3043214,-2.84217094e-14 127.996691,-2.84217094e-14 C198.694024,-5.1931977e-14 256,57.3046917 256,127.997518 L256,127.997518 Z" fill="#EC1C24"></path>
                    <path d="M117.024206,100.8896 L147.715303,131.570787 C152.145755,136.006194 159.337703,136.006194 163.77889,131.570787 C168.210168,127.134555 168.220077,119.948387 163.77889,115.512155 L133.086142,84.8194065 C111.124645,62.8537806 75.6306581,62.5003355 53.2042323,83.7037419 C52.9969548,83.8779871 52.7896774,84.0613161 52.5989161,84.2520774 C52.501471,84.3503484 52.4213677,84.4544 52.3239226,84.5419355 C52.2231742,84.637729 52.1224258,84.7244387 52.0266323,84.8202323 C51.8333935,85.013471 51.6558452,85.2157935 51.4865548,85.4172903 C30.2724129,107.854452 30.6374194,143.349265 52.5997419,165.314065 L83.2924903,195.998555 C87.7320258,200.43231 94.9148903,200.43231 99.3536,195.998555 C103.796439,191.562323 103.796439,184.366245 99.3651613,179.939923 L68.6724129,149.248 C55.2737032,135.855071 55.1836903,114.119019 68.3941161,100.611303 C81.9001806,87.4008774 103.624671,87.496671 117.024206,100.8896 Z M156.63649,60.0171355 C152.201084,64.4541935 152.201084,71.6395355 156.648052,76.0757677 L187.329239,106.768516 C200.71969,120.161445 200.8064,141.894194 187.5968,155.405213 C174.086606,168.604077 152.369548,168.517368 138.974142,155.122787 L108.282219,124.434168 C103.84351,119.99711 96.6490839,119.99711 92.2161548,124.434168 C87.7733161,128.867097 87.7733161,136.075561 92.2161548,140.499406 L122.898168,171.192981 C144.866271,193.157781 180.356129,193.515355 202.786684,172.308645 C202.994787,172.125316 203.191329,171.954374 203.397781,171.76031 C203.491097,171.665342 203.578632,171.567897 203.670297,171.470452 C203.769394,171.375484 203.866839,171.287123 203.960155,171.181419 C204.156697,170.99809 204.325161,170.796594 204.500232,170.586839 C225.7152,148.158761 225.360929,112.67551 203.397781,90.6983226 L172.705858,60.0163097 C168.270452,55.573471 161.067768,55.573471 156.63649,60.0171355 Z" fill="#FFFFFF"></path>
                  </g>
                </svg>
              </a>
              We support the
              <a href="https://authy.com/download/" target="_blank" rel="noopener">Authy app</a>, available for
              <a href="https://itunes.apple.com/us/app/authy/id494168017?mt=8" target="_blank" rel="noopener">iOS</a> and
              <a href="https://play.google.com/store/apps/details?id=com.authy.authy&amp;hl=en" target="_blank" rel="noopener">Android</a>.
              Download and enroll to receive codes in the app.
            </div>
          </jha-dashboard-radio-button>
        </form>
      </article>
      <footer>
        <jha-button type="button" id="alt-submit-button" on-click="_continueWithNewMethod" disabled="[[!_isMethodSelected(method)]]" is-loading="[[newMethodLoading]]" wide="">
          Next
        </jha-button>
      </footer>
    </template>
    <template is="dom-if" if="[[!showAnotherWay]]">
      <header>
        <h2>Enter verification code</h2>
      </header>
        <article>
          <p>
            We delivered a code to [[_getMaskedPhone(phone)]]. If you use the <a href="https://authy.com" target="_blank">Authy app</a>, get your code there.
          </p>
          <jha-form-floating-group id="code-group" error="[[_errorMessage]]">
            <!-- eslint-disable @banno/ux/no-auto-binding -->
            <input id="code" type="text" pattern="\\d*" value="{{code::input}}" spellcheck="false" autocomplete="one-time-code" autocorrect="off" autocapitalize="off" autofocus="" class="verification-code-input">
            <div class="label">Code</div>
          </jha-form-floating-group>
          <jha-form-checkbox name="rememberDevice">Remember this computer</jha-form-checkbox>
          <p class="try-again">
            Didn't get it? <a on-click="_resend">Resend</a> or <a on-click="_anotherWay">Try another way</a>
          </p>
        </article>
        <footer>
          <jha-button type="button" id="submit-button" disabled="[[!_isValid(code)]]" is-loading="[[isLoading]]" wide="" on-click="_next">
            Verify
          </jha-button>
          <template is="dom-if" if="[[enrolled]]">
            <div class="helper-text">
              No longer have access to this number? Use the <a href="https://authy.com/download/" target="_blank">Authy app</a> to reset your account or <a href="https://authy.com/phones/reset/?proceed=true">contact us</a>.
            </div>
          </template>
        </footer>
    </template>
  `;
  }
}
customElements.define(JhaAuthVerifyElement.is, JhaAuthVerifyElement);
export default JhaAuthVerifyElement;

import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaFormCheckboxElement = class extends PolymerElement {
  static get is() {
    return 'jha-form-checkbox';
  }

  static get properties() {
    return {
      checked: {
        type: Boolean,
        reflectToAttribute: true,
        notify: true,
      },
      disabled: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
      indeterminate: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
      label: {
        type: String,
        value: '',
      },
      required: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
    };
  }

  inputChanged_(evt) {
    this.checked = /** @type {HTMLInputElement} */ (evt.target).checked;
  }

  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
      }
      :host([disabled]) * {
        opacity: .6;
        cursor: default;
      }
      label {
        position: relative;
        display: var(--jha-form-checkbox-label-display, flex);
        align-items: var(--jha-form-checkbox-label-align-items, center);
        justify-content: var(--jha-form-checkbox-label-justify-content, flex-start);
        padding-top: 3px;
        margin-bottom: 3px;
        line-height: 18px;
        font-size: var(--jha-form-checkbox-font-size, 13px);
        cursor: pointer;
        width: var(--jha-form-checkbox-label-width, auto);
      }
      label * {
        pointer-events: none
      }
      label > input {
        position: relative;
        opacity: 0;
        z-index: 1;
        margin-right: 15px;
        flex-shrink: 0;
      }
      label > input:focus ~ span {
        width: 18px;
        height: 18px;
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
      label > input:disabled {
        visibility: hidden;
      }
      label > input:disabled ~ span {
        cursor: default;
      }
      label > span {
        border-radius: 2px;
        margin-left: -28px;
        margin-right: 12px;
        display: block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border: 1px solid var(--input-border-color, var(--jha-color-neutral));
        background-color: var(--jha-form-checkbox-color, rgba(0,0,0,0));
        background-size: 18px;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        z-index: 0;
        box-sizing: border-box;
        flex-shrink: 0;
      }

      .checked, .indeterminate {
        display: none;
      }
      
      .checked svg, .indeterminate svg { 
        width: 18px;
        height: 18px;
        fill: var(--jha-form-checkbox-check-color, var(--jha-color-white, #fff));
        stroke: var(--jha-form-checkbox-check-color, var(--jha-color-white, #fff));
        position: absolute;
        top: -1px;
        left: -1px;
      }

      label > input:indeterminate ~ span,
      label > input:checked ~ span {
        position: relative;
        border-color: var(--jha-form-checkbox-background-color, var(--jha-color-primary, #006ee4));
        background-color: var(--jha-form-checkbox-background-color, var(--jha-color-primary, #006ee4));
      }

      label > input:indeterminate ~ span .indeterminate,
      label > input:checked ~ span .checked {
        display: block;
      }

      :host([invite-dialog]) {
        width: 100%;
      }
    </style>
    <label>
      <input required$="[[required]]" aria-label$="[[label]]" type="checkbox" checked$="[[checked]]" checked="[[checked]]" disabled$="[[disabled]]" on-change="inputChanged_" indeterminate="[[indeterminate]]">
      <span>
        <div class="checked">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewbox="0 0 24 24">
            <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"/>
          </svg>
        </div>
        <div class="indeterminate">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" stroke="#fff" viewBox="0 0 24 24">
            <path d="M4 11v2h16v-2z"/>
          </svg>
        </div>
      </span>
      <slot></slot>
    </label>

  `;
  }
};
customElements.define(window.JhaFormCheckboxElement.is, window.JhaFormCheckboxElement);
export default window.JhaFormCheckboxElement;

/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconCheckmarkElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-checkmark';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.88,7.06,9.41,16.53,5.94,13.06a.75.75,0,0,0-1.06,1.06l4,4a.75.75,0,0,0,1.06,0l10-10a.75.75,0,1,0-1.06-1.06Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconCheckmarkElement.is,
    window.JhaIconCheckmarkElement);
export default window.JhaIconCheckmarkElement;

import ApiPaths from "../api-paths.js";
'use strict';
class AuthApi {
    static newAuthEnrollment() {
        return {
            url: `${ApiPaths.AUTH_PATH}/out-of-band`,
            requestMethods: ['POST']
        };
    }
    static authEnrollment() {
        return {
            url: function (userId) {
                return `${ApiPaths.AUTH_PATH}/out-of-band/${userId}`;
            },
            arguments: ['userId'],
            requestMethods: ['GET', 'PUT', 'DELETE']
        };
    }
    static authSession() {
        return {
            url: function (userId) {
                return `${ApiPaths.AUTH_PATH}/out-of-band/${userId}/session`;
            },
            arguments: ['userId'],
            requestMethods: ['GET']
        };
    }
    static sendSms() {
        return {
            url: function (userId) {
                return `${ApiPaths.AUTH_PATH}/out-of-band/${userId}/send`;
            },
            arguments: ['userId'],
            requestMethods: ['POST']
        };
    }
    static verifySms() {
        return {
            url: function (userId) {
                return `${ApiPaths.AUTH_PATH}/out-of-band/${userId}/verify`;
            },
            arguments: ['userId'],
            requestMethods: ['POST']
        };
    }
}
export default AuthApi;

/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconArrowBackElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-arrow-back';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7.2,12.8l5.9,6c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0l-7.2-7.3c-0.3-0.3-0.3-0.8,0-1.1l7.2-7.2
      c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1l-5.9,6h12c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H7.2z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconArrowBackElement.is,
    window.JhaIconArrowBackElement);
export default window.JhaIconArrowBackElement;

import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js';
/** @polymer */
class JhaAuthEnrollElement extends PolymerElement {
  static get is() {
    return 'jha-auth-enroll';
  }
  static get properties() {
    return {
      authRequired: Boolean,
      countryCode: {
        type: String,
        value: '+ 1',
        observer: JhaAuthEnrollElement.prototype._countryUpdated
      },
      isLoading: {
        type: Boolean,
        value: false
      },
      email: {
        type: String,
        observer: JhaAuthEnrollElement.prototype._emailObserver
      },
      phone: {
        type: String,
        observer: JhaAuthEnrollElement.prototype._phoneUpdated
      },
      submitDisabled: Boolean,
      userId: String,
      valid: {
        type: Object,
        value: {
          email: false,
          phone: false,
          country: true
        }
      }
    };
  }
  constructor() {
    super();
    this.boundKeyHandler_ = this._keyHandler.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.boundKeyHandler_);
    this.$.phone.focus();
  }

  _keyHandler(e) {
    const ENTER = 13;
    const pressedKey = e.keyCode;
    if (pressedKey === ENTER) {
      this._next();
    }
  }

  _checkValidity(event) {
    this.valid.email = event.target.checkValidity();
    this.submitDisabled = !this._hasValidInput();
  }

  _close() {
    this.dispatchEvent(new CustomEvent('close', {bubbles: true}));
  }

  _emailObserver(newEmail, oldEmail) {
    if (newEmail && !oldEmail) {
      this.$['email-group'].setAttribute('has-value', true);
      this.valid.email = this.$.email.checkValidity();
    }
  }

  _formatPhone(phone, countryCode = '1') {
    let formattedPhone = '';
    if (countryCode !== '1') {
      formattedPhone = phone.replace(/\D/g, '');
      this.valid.phone = formattedPhone.length > 0;
      return formattedPhone;
    }
    const match = phone.match(/(^\d{1,2}$)?(\d{3})?(\d{0,2}$)?(\d{3})?(\d{0,4})?/);
    if (!match[0]) {
      this.valid.phone = false;
    } else {
      const validPhone = match[2] + match[4] + match[5];
      if (validPhone) this.valid.phone = Boolean(validPhone.match(/(^\d{3})(\d{3})(\d{4}$)/));
      if (match[1]) formattedPhone = '(' + match[1];
      if (match[2]) formattedPhone = '(' + match[2] + ') ';
      if (match[3]) formattedPhone += match[3];
      if (match[4]) formattedPhone += match[4] + '-';
      if (match[5]) formattedPhone += match[5];
    }
    return formattedPhone;
  }

  _phoneInput(event) {
    let phone = event.target.value.replace(/\D/g, '');
    if (phone) {
      if (event.inputType === 'deleteContentBackward') {
        this.valid.phone = false;
        phone = this.phone.replace(/\D/g, '');
        phone = phone.slice(0, phone.length - 1);
      }
      if (event.data && !event.data.match(/(^\d$)/)) {
        event.target.value = this.phone;
      } else {
        this.phone = phone;
      }
    }
    this.submitDisabled = !this._hasValidInput();
  }

  _countryInput(event) {
    this.valid.country = this.$.country.checkValidity();
    this.submitDisabled = !this._hasValidInput();
  }

  _phoneUpdated(newPhone) {
    if (newPhone === '') {
      this.$['phone-group'].removeAttribute('has-value');
      this.$['submit-button'].setAttribute('disabled', true);
    } else if (newPhone && newPhone === newPhone.replace(/\D/g, '')) {
      this.phone = this._formatPhone(newPhone, this.countryCode.replace(/\D/g, ''));
    }
  }

  _countryUpdated(newCountry) {
    if (newCountry === '') {
      this.$['country-code-group'].removeAttribute('has-value');
      this.$['submit-button'].setAttribute('disabled', true);
    } else if (newCountry) {
      this.phone = this._formatPhone(this.phone, newCountry.replace(/\D/g, ''));
    }
  }

  _hasValidInput() {
    return this.valid.phone &&
      this.valid.email &&
      this.$.email.checkValidity() &&
      this.valid.country &&
      this.$.country.checkValidity();
  }

  _next(e) {
    if (!this.submitDisabled) {
      this.dispatchEvent(new CustomEvent('enroll', {
        bubbles: true,
        composed: true,
        detail: {
          email: this.email,
          countryCode: this.countryCode.replace(/\D/g, ''),
          phone: this.phone.replace(/\D/g, ''),
        },
      }));
    }
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      a {
        color: var(--jha-color-primary);
        text-decoration: none;
      }
      header,
      article,
      footer {
        text-align: center;
        padding-bottom: 25px;
      }
      header {
        padding: 16px 16px 0;
        border-bottom: 1px solid var(--jha-border-color);
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 18px;
        font-weight: 400;
        display: inline-block;
      }
      article {
        padding: 16px;
      }
      p {
        margin: 20px 20px 20px 20px;
        text-align: left;
      }
      jha-icon-close {
        position: absolute;
        margin-left: 160px;
        width: 16px;
      }
      jha-link-button {
        --jha-button-text: var(--jha-color-primary);
      }
      .phone-input {
        display: flex;
      }
      #country {
        border-right: 1px solid var(--jha-border-color);
        border-bottom: 1px solid var(--jha-border-color);
        width: 60px;
      }
      #phone {
        border-bottom: 1px solid var(--jha-border-color);
        padding-left: 8px;
      }
      #phone-group .label {
        padding-left: 8px;
      }
      #email-group {
        margin: 20px 20px 20px 20px;
      }
      #country-code-group {
        margin: 20px 0px 20px 20px;
      }
      #phone-group {
        margin: 20px 20px 20px 0px;
      }
    </style>
    <header>
      <h2>Secure your account</h2>
      <template is="dom-if" if="[[!authRequired]]">
        <jha-icon-close on-click="_close"></jha-icon-close>
      </template>
    </header>
      <article>
        <p>
          Help us secure your account by enrolling in two-factor authentication. When you login, we will send a one-time verification code to a device you have in your possession.
        </p>
        <p>
          If you already use the <a href="https://authy.com" target="_blank">Authy app</a> for two-factor authentication and would like to use it for this account, please register with the same information.
        </p>
        <jha-form-floating-group id="email-group">
          <!-- eslint-disable-next-line @banno/ux/no-auto-binding -->
          <input id="email" type="email" value="{{email::input}}" on-input="_checkValidity" spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off" required="">
          <div class="label">Email</div>
        </jha-form-floating-group>
        <div class="phone-input">
          <jha-form-floating-group has-value="" id="country-code-group">
            <!-- eslint-disable-next-line @banno/ux/no-auto-binding -->
            <input id="country" type="string" value="{{countryCode::input}}" spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off" pattern="(\\+\\s*)?\\d{1,3}" required>
            <div class="label">Country</div>
          </jha-form-floating-group>
          <jha-form-floating-group id="phone-group">
            <!-- eslint-disable-next-line @banno/ux/no-auto-binding -->
            <input id="phone" type="phone" value="{{phone::input}}" on-input="_phoneInput" spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off" autofocus="">
            <div class="label">Mobile number</div>
          </jha-form-floating-group>
        </div>
      </article>
      <footer>
        <template is="dom-if" if="[[!authRequired]]">
          <jha-link-button icon="" on-click="_close">Skip this</jha-link-button>
        </template>
        <jha-button id="submit-button" type="submit" disabled="[[submitDisabled]]" is-loading="[[isLoading]]" on-click="_next">Next</jha-button>
      </footer>
  `;
  }
}
customElements.define(JhaAuthEnrollElement.is, JhaAuthEnrollElement);
export default JhaAuthEnrollElement;

import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/* global Polymer*/
/**
 * @polymer
 * @customElement
 */
class JhaDashboardRadioButton extends PolymerElement {
  static get is() { return 'jha-dashboard-radio-button'; }
  static get properties() {
    return {
      checked: {
        type: Boolean,
        reflectToAttribute: true,
        computed: '_computeChecked(identifier, selectedidentifer)'
      },
      identifier: String,
      selectedidentifer: String
    };
  }
  connectedCallback() {
    super.connectedCallback();
    this.$.radioInput.addEventListener('change', this._checkRadioChecked);
  }

  _checkRadioChecked(e) {
    this.dispatchEvent(new CustomEvent('radio-button-selected', {
      bubbles: true,
      detail: this.identifier
    }));
  }

  _computeChecked(identifier, selectedidentifer) {
    if (identifier === selectedidentifer) {
      this.checked = true;
      this.$.radioInput.checked = true;
    } else {
      this.checked = false;
      this.$.radioInput.checked = false;
    }
  }


  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      label {
        position: relative;
        display: block;
        padding-left: 36px;
        padding-top: 2px;
        color: #6b757b;
        cursor: pointer;
        margin-bottom: 6px;
        font-size: 13px;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
      }
      span {
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #e4e7ea;
        background-size: 18px;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all .2s ease;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        z-index: 0;
      }
      input:focus ~ span {
        border-color: var(--jha-color-primary, #006ee4);
        box-shadow: 0 0 0 2px rgba(var(--jha-color-primary, #006ee4), .2);
      }
      input:disabled ~ span {
        opacity: 0.6;
        cursor: default;
      }
      input:checked ~ span {
        background-color: var(--jha-color-primary, #006ee4);
        border-color: var(--jha-color-primary, #006ee4);
      }
      input:checked ~ span::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50%;
      }
    </style>
    <label>
      <input on-click="_checkRadioChecked" id="radioInput" type="radio" checked$="[[checked]]">
      <span></span>
      <slot></slot>
    </label>
  `;
  }
}
customElements.define(JhaDashboardRadioButton.is, JhaDashboardRadioButton);
export default JhaDashboardRadioButton;

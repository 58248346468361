import JhaFetch from '../services/jha-fetch.js';
import AuthApi from '../services/auth/auth-api.js';
class TwoFactorController {
  static getEnrollmentInfo(args) {
    return JhaFetch.fetch({
      api: AuthApi.authEnrollment(),
      urlArguments: { userId: args.userId },
      method: 'GET'
    });
  }
  static getAuthSession(args) {
    return JhaFetch.fetch({
      api: AuthApi.authSession(),
      urlArguments: { userId: args.userId },
      method: 'GET'
    });
  }
  static enroll(args) {
    return JhaFetch.fetch({
      api: AuthApi.newAuthEnrollment(),
      body: {
        countryCode: args.countryCode || '1',
        email: args.email,
        phone: args.phone,
      },
      method: 'POST',
    });
  }
  static unenroll(args) {
    return JhaFetch.fetch({
      api: AuthApi.authEnrollment(),
      urlArguments: { userId: args.userId },
      method: 'DELETE'
    });
  }
  static sendCode(args) {
    return JhaFetch.fetch({
      api: AuthApi.sendSms(),
      body: {
        force: args.force || false,
        via: args.via || 'sms'
      },
      urlArguments: { userId: args.userId },
      method: 'POST'
    });
  }
  /**
   * @param {string} userId
   * @param {string} code
   * @param {boolean} rememberDevice
   */
  static verifyCode(userId, code, rememberDevice) {
    return JhaFetch.fetch({
      api: AuthApi.verifySms(),
      body: {
        code,
        rememberDevice
      },
      urlArguments: { userId },
      method: 'POST'
    }).catch(error => Promise.reject(error));
  }
}
export default TwoFactorController;
